define("responsive-bytes/routes/index", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        beforeModel: function beforeModel() {
            this.transitionTo("gradeconverter");
        }
    });
});