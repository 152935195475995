define('responsive-bytes/router', ['exports', 'ember', 'responsive-bytes/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    redirects: {
      'nontan': 'https://pacific-taiga-71002.herokuapp.com'
    }
  });

  Router.map(function () {
    this.route('gradeconverter');
    this.route('nontan');
  });

  exports.default = Router;
});