define('responsive-bytes/models/grade-system', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Object.extend({
        name: '',
        category: '',
        language: '',
        grades: [],
        init: function init(data) {
            this.name = data["name"];
            this.category = data["category"];
            this.language = data["language"];
            this.grades = [];
        },
        gradeFor: function gradeFor(index) {
            var grade = this.grades[index];
            if (grade.length > 0) {
                return grade;
            }

            var lowerGrade = this.lowerGradeFor(index);
            var higherGrade = this.higherGradeFor(index);

            if (lowerGrade.length == 0) {
                return "Easier than " + higherGrade;
            } else if (higherGrade.length == 0) {
                return "Harder than " + lowerGrade;
            } else {
                return lowerGrade + " ~ " + higherGrade;
            }
        },
        lowerGradeFor: function lowerGradeFor(index) {
            for (var i = index; i >= 0; i--) {
                var grade = this.grades[i];
                if (grade.length > 0) {
                    return grade;
                }
            }
            return "";
        },
        higherGradeFor: function higherGradeFor(index) {
            for (var i = index; i < this.grades.length; i++) {
                var grade = this.grades[i];
                if (grade.length > 0) {
                    return grade;
                }
            }
            return "";
        }
    });
});