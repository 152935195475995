define("responsive-bytes/controllers/gradeconverter", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Controller.extend({
        converterDisplayMode: "modal-hidden",
        selectedFromSystem: "",
        selectedFromGrades: [],
        selectedFromGrade: "",
        selectedToSystem: "",
        convertedGrade: "",
        convertedResultMode: "convert-result-hide",
        actions: {
            openModalConverter: function openModalConverter() {
                this.set('converterDisplayMode', "modal-show");
            },
            closeModalConverter: function closeModalConverter() {
                this.set('converterDisplayMode', 'modal-hidden');
            }
        }
    });
});