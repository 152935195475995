define('responsive-bytes/models/grade-system-table', ['exports', 'ember', 'responsive-bytes/models/grade-system'], function (exports, _ember, _gradeSystem) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Object.extend({
        systems: {},
        system_names: [],
        init: function init(data) {
            var lines = data["data"].split('\n');
            for (var i = 0; i < lines.length; i++) {
                var line = lines[i];
                var items = line.split(',');
                for (var j = 0; j < items.length; j++) {
                    var item = items[j];
                    if (i === 0) {
                        var system = new _gradeSystem.default({ name: item });
                        this.system_names.push(item);
                        this.systems[item] = system;
                    } else if (i === 1) {
                        var system_name = this.system_names[j];
                        var _system = this.systems[system_name];
                        _system.category = item;
                    } else if (i === 2) {
                        var _system_name = this.system_names[j];
                        var _system2 = this.systems[_system_name];
                        _system2.language = item;
                    } else {
                        var _system_name2 = this.system_names[j];
                        var _system3 = this.systems[_system_name2];
                        _system3.grades.push(item);
                    }
                }
            }
        },
        system_categories: function system_categories() {
            var ret = {};
            var self = this;
            this.system_names.forEach(function (name) {
                var system = self.systems[name];
                ret[name] = system.category;
            });
            return ret;
        },
        system_language: function system_language() {
            var ret = {};
            var self = this;
            this.system_names.forEach(function (name) {
                var system = self.systems[name];
                ret[name] = system.language;
            });
            return ret;
        },
        grades: function grades() {
            var ret = [];
            var self = this;
            var gradeCount = this.systems["Yosemite Decimal System"].grades.length;
            for (var i = 10; i < gradeCount; i++) {
                var retInt = {};
                this.system_names.forEach(function (name) {
                    var system = self.systems[name];
                    retInt[name] = system.grades[i];
                });
                ret.push(retInt);
            }
            return ret;
        },
        distinctGradesForSystem: function distinctGradesForSystem(name) {
            var ret = [];
            var system = this.systems[name];
            system.grades.forEach(function (grade) {
                if (grade.length == 0) {
                    return;
                }
                if (ret.contains(grade)) {
                    return;
                }
                ret.push(grade);
            });
            return ret;
        },
        gradeFor: function gradeFor(fromSystemName, fromGrade, toSystemName) {
            var fromSystem = this.systems[fromSystemName];
            var gradeIndex = fromSystem.grades.indexOf(fromGrade);
            var toSystem = this.systems[toSystemName];
            return toSystem.gradeFor(gradeIndex);
        }
    });
});