define('responsive-bytes/routes/gradeconverter', ['exports', 'ember', 'rsvp', 'responsive-bytes/models/grade-system-table'], function (exports, _ember, _rsvp, _gradeSystemTable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        default_system_names: ["Yosemite Decimal System", "French", "Hueco", "Fontainebleu", "Ogawayama"],
        model: function model() {
            var self = this;
            return new _rsvp.default.Promise(function (resolve) {
                _ember.default.$.get("https://s3.amazonaws.com/gradeconverter.yfujiki.com/GradeSystemTable.csv", function (data) {
                    resolve(data);
                });
            }).then(function (data) {
                var table = new _gradeSystemTable.default({ data: data });
                var names = table.system_names;
                var categories = table.system_categories();
                var grades = table.grades();

                var filteredNames = names.filter(function (name) {
                    return self.default_system_names.contains(name);
                });

                var filteredCategories = [];
                filteredNames.forEach(function (name) {
                    filteredCategories.push(categories[name]);
                });

                var filteredGrades = [];
                grades.forEach(function (grade) {
                    var gradeArray = [];
                    filteredNames.forEach(function (name) {
                        gradeArray.push(grade[name]);
                    });
                    filteredGrades.push(gradeArray);
                });
                filteredGrades.reverse();

                return _ember.default.RSVP.hash({
                    table: table,
                    names: names,
                    categories: categories,
                    grades: grades,
                    filteredNames: filteredNames,
                    filteredCategories: filteredCategories,
                    filteredGrades: filteredGrades
                });
            });
        }
    }
    // setupController(controller, model) {
    //     this._super(controller, model);
    //     Ember.$(window).on('load', controller.actions.fromSystemSelected());        
    // }
    );
});