define("responsive-bytes/components/conversion-panel", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Component.extend({
        converterDisplayMode: "modal-hidden",
        selectedFromSystem: "",
        selectedFromGrades: [],
        selectedFromGrade: "",
        selectedToSystem: "",
        convertedGrade: "",
        convertedResultMode: "convert-result-hide",
        actions: {
            fromSystemSelected: function fromSystemSelected() {
                var idString = "#fromSystem" + this.get('id');
                var selectedElement = _ember.default.$(idString)[0];
                var selectedIndex = selectedElement.selectedIndex;
                var selectedSystem = selectedElement.options[selectedIndex].value;
                this.set('selectedFromSystem', selectedSystem);

                var model = this.get('model');
                var table = model.table;
                this.set('selectedFromGrades', table.distinctGradesForSystem(selectedSystem));
            },
            toSystemSelected: function toSystemSelected() {
                var idString = "#toSystem" + this.get('id');
                var selectedElement = _ember.default.$(idString)[0];
                var selectedIndex = selectedElement.selectedIndex;
                var selectedSystem = selectedElement.options[selectedIndex].value;
                this.set('selectedToSystem', selectedSystem);
            },
            fromGradeSelected: function fromGradeSelected() {
                var idString = "#fromGrade" + this.get('id');
                var selectedElement = _ember.default.$(idString)[0];
                var selectedIndex = selectedElement.selectedIndex;
                var selectedSystem = selectedElement.options[selectedIndex].value;
                this.set('selectedFromGrade', selectedSystem);
            },
            convert: function convert() {
                var model = this.get('model');
                var table = model.table;

                var idString = "#fromSystem" + this.get('id');
                var fromSystems = _ember.default.$(idString)[0];
                var selectedFromIndex = fromSystems.selectedIndex;
                var selectedFromSystem = fromSystems.options[selectedFromIndex].value;

                idString = "#fromGrade" + this.get('id');
                var fromGrades = _ember.default.$(idString)[0];
                var selectedFromGradeIndex = fromGrades.selectedIndex;
                var selectedFromGrade = "";
                if (selectedFromGradeIndex === -1) {
                    selectedFromGrade = table.systems[selectedFromSystem].grades[0];
                    fromGrades.selectedIndex = 0;
                    this.actions.fromSystemSelected.bind(this)();
                } else {
                    selectedFromGrade = fromGrades.options[selectedFromGradeIndex].value;
                }

                idString = "#toSystem" + this.get('id');
                var toSystems = _ember.default.$(idString)[0];
                var selectedToSystemIndex = toSystems.selectedIndex;
                var selectedToSystem = toSystems.options[selectedToSystemIndex].value;

                var convertedGrade = table.gradeFor(selectedFromSystem, selectedFromGrade, selectedToSystem);
                this.set('convertedGrade', convertedGrade);
                var resultMode = convertedGrade.length > 0 ? "convert-result-show" : "convert-result-hide";
                this.set('convertedResultMode', resultMode);
            }
        }
    });
});